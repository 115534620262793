import Vue from "vue";
import VueRouter from "vue-router";
import index from "../views/index.vue";
// import youjiao from "../views/youjiao.vue";
// import zhijiao from "../views/SupplierManage.vue";
// import news from "../views/news.vue";
// import GroupNews from "../views/News/GroupNews.vue";
// import MediaCoverage from "../views/News/MediaCoverage.vue";
// import TradeNews from "../views/News/TradeNews.vue";
// import newsDetail from "../views/newsxiangqing.vue";
// import newsDetail1 from "../views/NewsDetals/newsxiangqing1.vue";
// import newsDetail2 from "../views/NewsDetals/newsxiangqing2.vue";
// import MediaCoverage0 from "../views/NewsDetals/MediaCoverage0.vue";
// import MediaCoverage1 from "../views/NewsDetals/MediaCoverage1.vue";
// import TradeNews0 from "../views/NewsDetals/TradeNews0.vue";
// import TradeNews1 from "../views/NewsDetals/TradeNews1.vue";
// import zhaopinxinxi0 from "../views/zhaopinxinxi0.vue";
// import zhaopinxinxi1 from "../views/Recruit-info/zhaopinxinxi1.vue";
// import zhaopinxiangqing0_1 from "../views/Recruitment-details/zhaopinxiangqing0-1.vue";
// import zhaopinxiangqing0_0 from "../views/Recruitment-details/zhaopinxiangqing0-0.vue";
// import zhaopinxiangqing1_0 from "../views/Recruitment-details/zhaopinxiangqing1-0.vue";
// import zhaopinxiangqing1_1 from "../views/Recruitment-details/zhaopinxiangqing1-1.vue";
import userPrivacy from "../views/NewsDetals/userPrivacy.vue";
import workerPrivacy from "../views/NewsDetals/workerPrivacy.vue";

// 报错解决
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push;

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace;

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: index,
    meta: {
      title: "首页",
    },
  },
  {
    path: "/serviceAssurance",
    name: "serviceAssurance",
    component: () => import("@/views/ServiceAssurance.vue"),
    meta: {
      title: "服务保障",
    },
  },
  {
    path: "/blueUnion",
    name: "blueUnion",
    component: () => import("@/views/BlueUnion.vue"),
    meta: {
      title: "蓝友联盟",
    },
  },
  {
    path: "/supplierManage",
    name: "supplierManage",
    component: () => import("@/views/SupplierManage.vue"),
    meta: {
      title: "供应商管理",
    },
  },
  {
    path: "/aboutUs",
    name: "aboutUs",
    component: () => import("@/views/AboutUs.vue"),
    meta: {
      title: "联系我们",
    },
  },
  {
    path: "/designerUnion",
    name: "designerUnion",
    component: () => import("@/views/DesignerUnion.vue"),
    meta: {
      title: "设计师联盟",
    },
  },
  {
    path: "/userPrivacy",
    name: "userPrivacy",
    component: userPrivacy,
    meta: {
      title: "用户隐私政策",
    },
  },
  {
    path: "/workerPrivacy",
    name: "workerPrivacy",
    component: workerPrivacy,
    meta: {
      title: "工人隐私政策",
    },
  },
];
// title

const router = new VueRouter({
  routes,
  // 跳转页面后 回到顶部
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});

export default router;

//title
const defaultTitle = "默认 title";
router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? to.meta.title : defaultTitle;
  next();
});
