<template>
  <!-- star -->

  <div class="youjiaopage_wrap">
    <nav2></nav2>
    <banner2
      class="banner"
      bannerH="关于我们"
      bannerP="专业有保障、服务更贴心"
      :bannerImg="require('@/assets/img/top_back.png')"
    ></banner2>
    <!-- 上部分 -->
    <div class="main">
      <h1 class="box-title">联系我们</h1>
      <div class="hot-line">
        <div>客服电话</div>
        <div>TEL 400-0919-897</div>
      </div>

      <div class="hot-line">
        <div>详细地址</div>
        <div>福建省泉州市丰泽区东海泰禾广场SOHO9号楼</div>
      </div>
    </div>
    <!-- 下部分 -->
    <div class="talk_bottom">
      <img src="../assets/img/lianxi/位图.png" alt="" />
    </div>
    <footer2></footer2>

    <!-- end -->
  </div>
</template>

<script>
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
import nav2 from "../components/nav.vue";
export default {
  name: "keji",
  components: {
    footer2,
    nav2,
    banner2,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped lang="scss">
/*去内外边距*/
html,
body {
  font-family: "微软雅黑", "宋体", Arail, Tabhoma;
  text-align: left;
}
/*字体 文本居左*/
ul {
  list-style: none;
}
/*去ul li 小点*/
img {
  border: 0 none;
}
/*img IE6 兼容问题*/
input,
select,
textarea {
  outline: 0;
}
/*去除点击蓝框*/
textarea {
  resize: none;
  overflow: auto;
}
/*锁定多行文本框大小 与文字超出出现滚动条*/
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/*表格边框合并 边框间距0*/
th,
strong,
var,
em {
  font-weight: normal;
  font-style: normal;
}
/*特殊文字粗细 样式显示正常*/
a {
  text-decoration: none;
}
/*a标签去下划线*/
.clearfix:after {
  clear: both;
  content: "";
  display: block;
  height: 0;
}
/*清浮动*/
a {
  color: #000000;
}
/*==========================Reset_End===========================*/

.youjiaopage_wrap {
  width: 100vw;
  margin: 0 auto;
  height: 1000px;
}

.main {
  .box-title {
    text-align: center;
    font-weight: bold;
    font-size: 38px;
    color: #1f2329;
    margin-bottom: 40px;
  }
  .hot-line {
    font-weight: 500;
    font-size: 24px;
    color: #444444;
    text-align: center;
    margin-bottom: 40px;
  }
}

.talk_bottom {
  width: 1200px;
  margin: 0 auto 120px;
  margin-bottom: 81px;
}
</style>
