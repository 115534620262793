<template>
  <div class="GroupOverview">
    <daohang></daohang>
    <banner2
      bannerH="服务保障"
      bannerP="专业有保障，服务更贴心"
      :bannerImg="require('@/assets/img/top_back.png')"
    ></banner2>
    <!-- 关于企业 -->
    <div class="about-bg">
      <img class="back-img" src="@/assets/img/蒙版.png" alt="" />
      <div class="about" id="about">
        <div class="about-top">
          <div class="about-top-l">
            <span class="title"> 责任守护 </span>
            <p>
              狸小住的服务保障计划是基于用户权益保障体系，贴身打造的服务而启动的全新保障计划，以“服务零售后、售后零风险、用户零损失”为最终目标，面对每一个风,险我们都挺身承担，对每一次服务负责到底!
            </p>
          </div>
          <div class="about-top-r">
            <div class="img-yinying"></div>
            <img
              src="https://img.js.design/assets/img/613eaa7ba9696d53260506a9.png"
              alt=""
            />
          </div>
        </div>
        <div class="about-down">
          <div class="item" v-for="(item, index) in aboutDownList" :key="index">
            <span class="item-1">0{{ index + 1 }}</span>
            <span class="item-2">+</span>
            <span class="item-3">{{ item }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 售后守护 -->
    <div class="qywh" id="CultureInfo">
      <h1>售后守护</h1>
      <!-- <div class="qywh-items"> -->
      <div class="qywh-item">
        <div class="qywh-item1" v-for="(item, index) in guardList" :key="index">
          <img
            class="qywh-icon"
            :src="require(`@/assets/img/serve/icon_guard_${index + 1}.png`)"
            alt=""
          />
          <p class="qywh-title">{{ item.title }}</p>
          <p class="qywh-content">
            {{ item.content }}
          </p>
        </div>
      </div>
      <!-- </div> -->
    </div>
    <!-- 规则 -->
    <div class="President">
      <div class="President_content">
        <div class="President-title">
          <p>规则</p>
        </div>
        <div class="President-item">
          <div v-for="(item, index) in ruleList" :key="index">
            <div class="title">
              {{ item.title }}
            </div>
            <div class="content">{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <footer2></footer2>
  </div>
</template>

<script>
import daohang from "../components/nav.vue";
import footer2 from "../components/footer.vue";
import banner2 from "../components/banner.vue";
export default {
  name: "App",
  data() {
    return {
      aboutDownList: ["专业师傅", "服务企业商家", "服务家庭", "用户好评"],
      guardList: [
        {
          title: "申述必查",
          content: "售后一经发起，平台客服快速响应、多方问题，明确权责。",
        },
        {
          title: "调查必严",
          content: "不偏袒，不徇私。做到全程管控，判决公平公正公开。",
        },
        {
          title: "有判必偿",
          content: "判定成立的售后订单，平台将为您补偿相应金额的返利金额",
        },
        {
          title: "违规必补",
          content: "对于判定成立的售后订单，系统按条例立即补偿，绝无疏漏。",
        },
      ],
      ruleList: [
        {
          title: "交易有担保 师傅放心找:",
          content:
            "平台严选保证金师傅，服务数据清晰展现，全国海量优质师傅在线为您服务，平台将提供真实可靠的师傅服务数据并履行专业服务承诺，减少您的用工风险，让您雇佣无忧。",
        },
        {
          title: "专业有保障 服务更贴心:",
          content:
            "从报价、接单、预约、上门、服务、完工….每道环节都有标准的流程规范。在服务过程中的全流程节点进行实时监控，在师傅服务过程中严格遵循相关服务标准!致力保障您的下单体验!",
        },
        {
          title: "售后有担保 交易没烦恼:",
          content:
            "服务质量我们保障!因服务质量问题导致产品故障，平台将质保一年，免费安排上门检修。当发生售后问题与师傅协商不一致时，用户可在平台发起售后仲裁，经客服核实确认售后属实后，平台将对用户进行相应补偿。",
        },
      ],
    };
  },
  components: {
    daohang,
    footer2,
    banner2,
  },
  created() {
    this.$nextTick(() => {
      this.toLocal();
    });
  },
  mounted() {
    let _this = this;
    _this.$nextTick(function () {
      window.addEventListener("scroll", _this.handleScroll);
    });
  },
  methods: {
    toLocal() {
      //查找存储的锚点id
      let Id = localStorage.getItem("toId");
      let toElement = document.getElementById(Id);
      //锚点存在跳转
      if (Id) {
        toElement.scrollIntoView();
        localStorage.setItem("toId", "");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.GroupOverview {
  width: 100vw;
  margin: 0 auto;
}
.about {
  height: 856px;
  position: relative;
  //   margin: 100px 200px;
  width: 1200px;
  margin: 0 auto;
}

.about-bg {
  position: relative;
  .back-img {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.1;
  }
  //   background-image: url("../assets/img/蒙版.png");
}

.about-top {
  position: relative;
  display: flex;
}

.about-top-l {
  position: absolute;
  left: 20px;
  top: 85px;
  width: 611px;
  height: 442px;
  .title {
    font-weight: bold;
    font-size: 38px;
    color: #444444;
    margin-bottom: 40px;
  }
  p {
    width: 508px;
    white-space: wrap;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #444444;
    line-height: 30px;
    font-style: normal;
  }
}

.img-yinying {
  position: absolute;
  top: 58px;
  left: -17px;
  width: 17px;
  height: 408px;
  background: #f8f8f8;
}

.about-top-r {
  position: absolute;
  right: 0;
  top: 45px;
}

.about-top-r img {
  width: 487px;
  height: 517px;
}

.about-down {
  position: absolute;
  bottom: 71px;
  display: flex;
  gap: 84px;
  width: 246px;
  height: 84px;

  .item {
    height: 100%;
    width: 246px;
    min-width: 246px;
    border-right: solid 1px #444444;
    text-align: left;
    color: #444;
  }

  .item-1 {
    // color: rgba(35, 51, 127, 1);
    font-size: 60px;
  }

  .item-2 {
    position: absolute;
    // color: rgba(35, 51, 127, 1);
    font-size: 16.8px;
  }

  .item-3 {
    // position: absolute;
    // left: 88px;
    margin-top: 41px;
    // color: rgba(102, 102, 102, 1);
    font-size: 22px;
    // width: 100px;
  }
}
.qywh {
  //   margin: 0 auto;
  //   width: 1200px;
  height: 598px;
}

.qywh h1 {
  margin: 42px 0;
  text-align: center;
  font-weight: bold;
  font-size: 38px;
  color: #1f2329;
}
.qywh-item {
  width: 1200px;
  margin: 30px auto;
  display: flex;
  justify-content: space-between;
}

.qywh-item1 {
  width: 288px;
  min-width: 288px;
  height: 348px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  text-align: center;
  padding: 53px 67px 57px;
  box-sizing: border-box;
  .qywh-icon {
    width: 90px;
    height: 90px;
    margin-bottom: 16px;
  }
  .qywh-title {
    font-weight: bold;
    font-size: 24px;
    color: #444444;
    margin-bottom: 16px;
  }
  .qywh-content {
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    color: #444444;
    font-family: PingFang SC, PingFang SC;
  }
}

/* 总裁寄语 */
.President {
  width: 100%px;
  height: 801px;
  background-image: url(../assets/img/摄图网_500941399_banner_企业文化背景（非企业商用）.png);
  background-size: 100% 801px;
  padding-top: 84px;
}

.President-title > p {
  font-size: 32px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
  line-height: 45px;
  text-align: center;
}

.President-item {
  width: 1200px;
  height: 465px;
  margin: 55px auto;
  background: #ffffff;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  font-size: 18px;
  padding: 71px 100px 0;
  line-height: 30px;
  .title {
    color: #5e81f4;
  }
  .content {
    color: #444444;
  }
}
</style>
